<template>
    <div v-if="controlInfo">
        <v-form class="wrapperForm">

            <v-row dense>

                <!--Секция Внутренний контроль-->
                <v-col cols="12" sm="12" md="6" class="section-wrapper">                    
                    
                    <v-card flat>
                        <v-card-text>
                            <div class="form-box-title">
                                <template v-if="isPeriodicity">
                                    {{$t("Внутренний_периодический_контроль")}}
                                </template>
                                <template v-else>
                                    {{$t("Внутренний_контроль")}}
                                </template>
                            </div>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="4">
                                    <label class="f-label">{{$t("Тип_контроля")}}</label>
                                </v-col>
                                <v-col  cols="12" sm="12" md="8">
                                    <div class="onlyReadData">{{ $t(GetControlTypes[document.Card.ControlType]) }}</div>
                                </v-col>
                            </v-row>

                            <template v-if="document.Card.ControlType !== 0">
                                
                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="f-label">{{$t("На_контроль_поставил")}}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="8">
                                        <div class="onlyReadData">
                                            <v-employee-chip :id="document.Card.TakeControlEmployeeId" :name="document.Card.TakeControlEmployeeName" />
                                        </div>
                                    </v-col>
                                </v-row>

                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="f-label">{{$t("Дата_постановки_на_контроль")}}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="8">
                                        <div class="onlyReadData">
                                            {{ document.Card.TakeControlDate ? $moment(document.Card.TakeControlDate).format("DD.MM.YYYY") : $t("Не_указано") }}
                                        </div>
                                    </v-col>
                                </v-row>

                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="f-label">{{$t("Первичный_срок")}}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="8">
                                        <div class="onlyReadData">
                                            {{ document.Card.FirstControlDate ? $moment(document.Card.FirstControlDate).format("DD.MM.YYYY") : $t("Не_указано") }}
                                        </div>
                                    </v-col>
                                </v-row>

                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="f-label">{{$t("Контрольный_срок")}}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="8">
                                        <div class="onlyReadData">
                                            {{ document.Card.ControlDate ? $moment(document.Card.ControlDate).format("DD.MM.YYYY") : $t("Не_указано") }}
                                        </div>
                                    </v-col>
                                </v-row>

                                <template v-if="isPeriodicity">

                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Следующий_контрольный_срок")}}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="8">
                                            <div class="onlyReadData">
                                                {{ document.Card.PeriodNextDate ? $moment(document.Card.PeriodNextDate).format("DD.MM.YYYY") : $t(this.document.Card.Periodicity == 4 ? "Задается_вручную" : "Не_указано") }}
                                            </div>
                                        </v-col>
                                    </v-row>

                                    <v-row no-gutters>
                                        <v-col cols="12" sm="12" md="4">
                                            <label class="f-label">{{$t("Дата_окончания_контроля")}}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="8">
                                            <div class="onlyReadData">
                                                {{ document.Card.PeriodEndDate ? $moment(document.Card.PeriodEndDate).format("DD.MM.YYYY") : $t("Не_указано") }}
                                            </div>
                                        </v-col>
                                    </v-row>

                                </template>    

                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="f-label">{{$t("Фактический_срок")}}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="8">
                                        <div class="onlyReadData">
                                            {{ document.Card.ExecDate ? $moment(document.Card.ExecDate).format("DD.MM.YYYY") : $t("Не_указано") }}
                                        </div>
                                    </v-col>
                                </v-row>

                                <v-row v-if="isCommon"
                                    no-gutters
                                >
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="f-label">{{$t("Ответственный_исполнитель")}}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="8">
                                        <div v-if="true" class="onlyReadData">
                                            <v-workplace-chip :id="controlInfo.mainExecuterId" :name="controlInfo.mainExecuterName" />
                                        </div>
                                        <div v-else class="onlyReadData">
                                            {{ $t("Не_указано") }}
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row v-else
                                    no-gutters
                                >
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="f-label">{{$t("Фактический_ответственный_исполнитель")}}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="8">
                                        <div v-if="controlInfo.factExecuterId!='00000000-0000-0000-0000-000000000000'" class="onlyReadData">
                                            <v-workplace-chip :id="controlInfo.factExecuterId" :name="controlInfo.factExecuter" />
                                        </div>
                                        <div v-else-if="controlInfo.factExecuterId=='00000000-0000-0000-0000-000000000000' && controlInfo.factExecuter" class="onlyReadData">
                                            <v-enterprise-chip :name="controlInfo.factExecuter"/>
                                        </div>
                                        <div v-else class="onlyReadData">
                                            {{ $t("Не_указано") }}
                                        </div>
                                    </v-col>
                                </v-row>

                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="f-label">{{$t("C_контроля_снял")}}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="8">
                                        <div class="onlyReadData">
                                            <v-employee-chip v-if="document.Card.TakeOffControlEmployeeId" :id="document.Card.TakeOffControlEmployeeId" :name="document.Card.TakeOffControlEmployeeName" />
                                            <span v-else>{{ $t("Не_указано") }}</span>
                                        </div>
                                    </v-col>
                                </v-row>

                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="f-label">{{$t("Дата_снятия_с_контроля")}}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="8">
                                        <div class="onlyReadData">
                                            {{ document.Card.TakeOffDate ? $moment(document.Card.TakeOffDate).format("DD.MM.YYYY") : $t("Не_указано") }}
                                        </div>
                                    </v-col>
                                </v-row>

                            </template>

                        </v-card-text>
                    </v-card>

                </v-col>

                <!--Секция Внешний контроль-->
                <v-col cols="12" sm="12" md="6" class="section-wrapper" v-if="document.Card.TypeC4 != 1">
                    
                    <v-card flat>
                        <v-card-text>

                            <div class="form-box-title">{{$t("Внешний_контроль")}}</div>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="4">
                                    <label class="f-label">{{$t("Тип_контроля")}}</label>
                                </v-col>
                                <v-col  cols="12" sm="12" md="8">
                                    <div class="onlyReadData">
                                        {{ document.Card.OuterControlType ? document.Card.OuterControlType.Value : $t("Не_указано") }}
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row no-gutters v-if="document.Card.OuterControlType || document.Card.OuterLimit">
                                <v-col cols="12" sm="12" md="4">
                                    <label class="f-label">{{$t("Внешний_срок")}}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <div class="onlyReadData">
                                        {{ document.Card.OuterLimit ? $moment(document.Card.OuterLimit).format("DD.MM.YYYY") : $t("Не_указано") }}
                                    </div>
                                </v-col>
                            </v-row>

                            <template v-if="document.Card.OuterControlType">

                                <v-row no-gutters>
                                    <v-col cols="12" sm="12" md="4">
                                        <label class="f-label">{{$t("Дата_снятия_с_контроля")}}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="8">
                                        <div class="onlyReadData">
                                            {{ document.Card.OuterTakeOffDate ? $moment(document.Card.OuterTakeOffDate).format("DD.MM.YYYY") : $t("Не_указано") }}
                                        </div>
                                    </v-col>
                                </v-row>

                            </template>

                        </v-card-text>
                    </v-card>

                </v-col>

                <!--Секция Сведения об исполнении-->
                <v-col cols="12" sm="12" md="12" class="section-wrapper" v-if="controlInfo && controlInfo.hasExecution">

                    <v-card flat>
                        <v-card-text>
                            <div class="form-box-title">{{$t("Сведения_об_исполнении")}}</div>

                            <template v-if="controlInfo.isPeriodic === true">

                                <v-expansion-panels 
                                    mandatory
                                    flat
                                    accordion
                                    tile
                                    hover
                                    focusable
                                    class="with-grid-ex-panel"
                                >
                                    <v-expansion-panel
                                        v-for="(execution,i) in executionsSorted"
                                        :key="i"  
                                    >
                                        <v-expansion-panel-header class="rounded">
                                            {{ $t("За_период_до_value.message", { value: execution.ControlDate ? $moment(execution.ControlDate).format("DD.MM.YYYY") : $t("Не_указано") }) }}
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            
                                            <v-row class="full-width-row" no-gutters>
                                                <v-col cols="12" sm="12" md="2">
                                                    <label class="f-label">{{$t("Кем_исполнено")}}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="10">
                                                    <div class="onlyReadData">
                                                        <v-employee-chip :id="execution.ExecuterID" :name="execution.ExecuterName" />
                                                    </div>
                                                </v-col>
                                            </v-row>

                                            <v-row class="full-width-row" no-gutters>
                                                <v-col cols="12" sm="12" md="2">
                                                    <label class="f-label">{{$t("Дата_исполнения")}}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="10">
                                                    <div class="onlyReadData">
                                                        {{ execution.ExecDate ? $moment(execution.ExecDate).format("DD.MM.YYYY") : $t("Не_указано") }}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                            
                                            <v-row class="full-width-row" no-gutters>
                                                <v-col cols="12" sm="12" md="2">
                                                    <label class="f-label">{{$t("Текст_исполнения")}}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="10">
                                                    <div class="onlyReadData">{{ execution.Comment }}</div>
                                                </v-col>
                                            </v-row>

                                            <v-row class="full-width-row" no-gutters v-if="execution.DocumentName">
                                                <v-col cols="12" sm="12" md="2">
                                                    <label class="f-label">{{$t("Документ_исполнения")}}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="10">
                                                    <div class="onlyReadData">
                                                        <v-document-link
                                                            :id="execution.DocumentID"
                                                            :type="'Chancellery|Documents.Document'"
                                                            :text="execution.DocumentName"
                                                            :is-direct-link="false"
                                                        />
                                                    </div>
                                                </v-col>
                                            </v-row>

                                            <v-row class="full-width-row" no-gutters v-if="execution.OtherAttachments">
                                                <v-col cols="12" sm="12" md="2">
                                                    <label class="f-label">{{$t("Вложения")}}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="10">
                                                    <div class="onlyReadData more-per-lab-wrap">

                                                        <v-chip v-for="file in execution.OtherAttachments.data_list" :key="execution.OtherAttachments.data_list.indexOf(file)"
                                                            class="person-label file-label"
                                                            small
                                                            label
                                                        >
                                                            <i class="fas fa-file-alt"></i>
                                                            <div>{{file.Name}}</div>
                                                            <i class="fas fa-download" @click="onDownload(file.id, file.Name)"></i>
                                                        </v-chip>

                                                    </div>
                                                </v-col>
                                            </v-row>
                                            
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>

                            </template>

                            <template v-else>

                                <v-row class="full-width-row" no-gutters>
                                    <v-col cols="12" sm="12" md="2">
                                        <label class="f-label">{{$t("Кем_исполнено")}}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="10">
                                        <div class="onlyReadData">
                                            <v-employee-chip :id="controlInfo.executerWorkplaceId" :name="controlInfo.executerName" />
                                        </div>
                                    </v-col>
                                </v-row>

                                <v-row class="full-width-row" no-gutters>
                                    <v-col cols="12" sm="12" md="2">
                                        <label class="f-label">{{$t("Дата_исполнения")}}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="10">
                                        <div class="onlyReadData">
                                            {{ controlInfo.executionDate ? $moment(controlInfo.executionDate).format("DD.MM.YYYY") : $t("Не_указано") }}
                                        </div>
                                    </v-col>
                                </v-row>
                                
                                <v-row class="full-width-row" no-gutters>
                                    <v-col cols="12" sm="12" md="2">
                                        <label class="f-label">{{$t("Текст_исполнения")}}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="10">
                                        <div class="onlyReadData">{{ controlInfo.executionText }}</div>
                                    </v-col>
                                </v-row>

                                <v-row class="full-width-row" no-gutters v-if="controlInfo.hasAttachedDocument">
                                    <v-col cols="12" sm="12" md="2">
                                        <label class="f-label">{{$t("Документ_исполнения")}}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="10">
                                        <div class="onlyReadData">
                                            <v-document-link
                                                :id="controlInfo.attachedDocumentId"
                                                :type="'Chancellery|Documents.Document'"
                                                :text="controlInfo.attachedDocumentName"
                                                :is-direct-link="false"
                                            />
                                        </div>
                                    </v-col>
                                </v-row>

                                <v-row class="full-width-row" no-gutters v-if="controlInfo.hasAttachments">
                                    <v-col cols="12" sm="12" md="2">
                                        <label class="f-label">{{$t("Вложения")}}</label>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="10">
                                        <div class="onlyReadData more-per-lab-wrap">

                                            <v-chip v-for="file in controlInfo.attachments" :key="controlInfo.attachments.indexOf(file)"
                                                class="person-label file-label"
                                                small
                                                label
                                            >
                                                <i class="fas fa-file-alt"></i>
                                                <div>{{file.name}}</div>
                                                <i class="fas fa-download" @click="onDownload(file.id, file.name)"></i>
                                            </v-chip>

                                        </div>
                                    </v-col>
                                </v-row>

                            </template>

                        </v-card-text>
                    </v-card>

                </v-col>

            </v-row>

        </v-form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { httpAPI } from "@/api/httpAPI"

export default {
    name: "ControlTab",
    props: {
        document: Object
    },
    computed: {
        ...mapGetters('references', ['GetControlTypes']),
        ...mapGetters('actionsource', { controlInfo: 'getControlInfo', isCommon: 'isDataSourceCommon' }),
        isPeriodicity() {
            if (this.document?.Card?.Periodicity == 0)
                return false;

            return true;
        },
        executionsSorted() {
            return this.controlInfo?.executions
                ? this.$_(this.controlInfo?.executions).orderBy(['ControlDate'], ['desc']).value()
                : [];
        }
    },
    methods: {
        update() {
            //чтобы не было критов
        },
        async onDownload(attachId, attachName) {
            // create URL
            let url = `api/download/attachment?id=${attachId}&name=${attachName}&mode=${process.env.NODE_ENV}&isCommon=${this.$store.getters['actionsource/isDataSourceCommon']}`;

            if (process.env.NODE_ENV === "production") {
                url += `&token=${this.$store.getters['auth/getUserInfo']?.token}`;
                const link = document.createElement('a');
                link.href = url;
                link.download = attachName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            else {
                let response = await httpAPI({ url, method: 'GET', responseType: 'blob' });

                if (response) {
                    const url = URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', attachName);
                    document.body.appendChild(link);
                    link.click();
                }
            }
        },
    }
}
</script>