<template>
    <v-card 
        v-if="!!controlInfo && (!!controlInfo.innerControlDate || !!controlInfo.outerControlDate)"
        class="execution-form-widget" 
        flat
    >
        <v-card-text :class="getClassByDocumentStatus()">

            <div
                v-if="!!controlInfo.innerControlDate" 
                class="efw-title"
            >
                <span v-if="controlInfo.controlType == 100">
                    {{$t("Снят_с_контроля")}}
                </span>

                <template v-else>
                    <template>{{$t("Контрольный_срок")}}:</template>
                    <template> {{ controlInfo.innerControlDate | formattedDate }}, {{ $t(documentStatus.text) }}</template>
                </template>              

            </div>

            <div
                v-if="!!controlInfo.outerControlDate" 
                class="efw-title"
            >
                <template>{{ $t("Внешний_срок") }}:</template>
                <template v-if="isOuterControlDateToday"> {{ controlInfo.outerControlDate | formattedDate }} ({{ $t("Сегодня") }})</template>
                <template v-else> {{ controlInfo.outerControlDate | formattedDate }}</template>
                <template>, {{ controlInfo.outerControlType }}</template>

            </div>

            <div 
                v-if="!!controlInfo.mainExecuterId"
                class="efw-desc-wrap"
            >
                <div class="efw-description">{{$t("Ответственный_исполнитель")}}:</div>
                <v-workplace-chip :id="controlInfo.mainExecuterId" :name="controlInfo.mainExecuterName" :main="true" />
            </div>

            <div 
                v-if="!!controlInfo.mainExecuterId && configuration == 'C4' && !(isCommon && dsType === 'IncomingDocument')"
                class="efw-desc-wrap"
            >
                <div class="efw-description">{{$t("Фактический_исполнитель")}}:</div>
                <v-enterprise-chip v-if="controlInfo.factExecuterId == '00000000-0000-0000-0000-000000000000'" :id="controlInfo.factExecuterId" :name="controlInfo.factExecuter" />
                <v-workplace-chip v-else :id="controlInfo.factExecuterId" :name="controlInfo.factExecuter" :main="true" />
            </div>            
            
        </v-card-text>
    </v-card>
</template>

<script>
import sys from '@/services/system'
import { mapGetters } from 'vuex';

export default {
    classes: ["mdwi-gr-grey", "mdwi-gr-green", "mdwi-gr-aqua" ],
    props: 
    {
        controlInfo: 
        {
            type: Object,
            default: null
        }
    },
    methods: {
        getClassByDocumentStatus() {
            var statusName = Object.keys(this.avaibleStatuses).find(key => this.avaibleStatuses[key] === this.documentStatus);

            switch (statusName)
            {
                case "done":
                    return "mdwi-gr-green"; //зеленый
                
                case "doneExpired":
                    return "mdwi-gr-green-bordered"; // зеленый с красной чертой
                
                case "consideration":
                case "work":
                    return "mdwi-gr-yellow"; //желтый

                case "today":
                    return "mdwi-gr-orange"; //оранжевый
                
                case "considerationExpired":
                case "expired":
                    return "mdwi-gr-aqua"; // красный

                case "takeOffControl":
                    return "mdwi-gr-purple"; // фиол

                default:
                    return "mdwi-gr-grey";
            }
        },
        getStatusIndex() {
            if (this.controlInfo.mainExecuteExist && this.controlInfo.controlType != 100)
                return this.avaibleStatuses['done'];
            
            else if (this.controlInfo.innerControlDate && this.controlInfo.controlType != 100) {

                let innerControlDate = this.$moment(this.controlInfo.innerControlDate);
                let currentTime = this.$moment(this.controlInfo.currentTime);

                if (!!innerControlDate && innerControlDate.isValid() && !!currentTime && currentTime.isValid()) {
                    if (Math.round((currentTime.toDate() - innerControlDate)/(1000*60*60*24)) > 1)
                        return this.avaibleStatuses['expired'];
                }
            }

            return this.avaibleStatuses['work'];
        }
    },
    computed:
    {
        ...mapGetters('actionsource', { isCommon: 'isDataSourceCommon', dsType: 'getDataSourceType' }),
        configuration(){
            return process.env.VUE_APP_CONFIGURATION;
        },
        avaibleStatuses() {
            return this.$store.getters['references/GetAvaibleStatuses'];
        },
        isInnerControlDateToday()
        {
            let innerControlDate = this.$moment(this.controlInfo.innerControlDate);
            let currentTime = this.$moment(this.controlInfo.currentTime);

            if (!!innerControlDate && innerControlDate.isValid() && !!currentTime && currentTime.isValid()) 
            {
                return innerControlDate.isSame(currentTime, 'day');
            }

            return false;
        },
        isOuterControlDateToday()
        {
            let outerControlDate = this.$moment(this.controlInfo.outerControlDate);
            let currentTime = this.$moment(this.controlInfo.currentTime);

            if (!!outerControlDate && outerControlDate.isValid() && !!currentTime && currentTime.isValid()) 
            {
                return outerControlDate.isSame(currentTime, 'day');
            }

            return false;
        },
        documentStatus() {
            if (this.dsType === 'InnerDocument')
                return this.getStatusIndex();

            return this.avaibleStatuses[this.$helpers.calculateDocumentStatus(this.controlInfo.controlType, this.controlInfo.innerControlDate, this.controlInfo.executionDate, this.controlInfo.factExecuterId != sys.guidEmpty())];        
        },
    },
    filters: 
    {
        formattedDate: (value) => {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    mounted() {
        //console.log(this.controlInfo);
    }
}
</script>